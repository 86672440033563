<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Nạp tiền tài khoản mua vé</div>
      <div class="header-link">
        <router-link to="/tai-khoan/lich-su-nap-tien/">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g transform="translate(-1095 -280)">
              <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
              <path
                d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
                transform="translate(1096.98 290.313)"
                fill="#666"
              />
            </g>
          </svg>
          Lịch sử nạp tiền
        </router-link>
      </div>
    </div>
    <hr class="d-none d-lg-block" />
    <Spinner v-if="isLoading || paymentProcessing" />
    <div class="section-content" v-else>
      <div class="content-container method-container">
        <div class="d-flex flex-row justify-content-between align-items-center mb-2">
          <div class="content-title mb-0">Hình thức thanh toán</div>
          <a v-if="isMobile && selectedMethod.id" class="content-title-update mt-0" @click="onUnSelectPaymentMethod()"
            >THAY ĐỔi</a
          >
        </div>
        <b-card
          class="customCard"
          :class="{
            'mr-3': selectedMethod && selectedMethod.id
          }"
        >
          <div
            v-for="method in paymentMethods"
            :key="'method_' + method.id"
            v-bind:class="{
              'payment-method': true,
              selected: method.id == selectedMethod.id,
              customCard__item: true
            }"
            v-show="isMobile && selectedMethod.id ? method.id === selectedMethod.id : true"
            @click="selectMethod(method)"
          >
            <div class="payment-detail">
              <div class="payment-image">
                <img :src="method.image_url" alt style="height: 40px; width: 40px" />
              </div>
              <div class="payment-info">
                <p class="payment-name">{{ method.name }}</p>
                <p class="payment-type" v-if="method.deposit_fee === 0 && method.deposit_rate === 0">
                  Miễn phí{{ method.handler === "vnpay-qr" ? " - Mở app ngân hàng để quét mã VNPAY QR" : "" }}
                </p>
                <p class="payment-type" v-else>
                  {{
                    `Phí giao dịch ${method.deposit_rate ? method.deposit_rate * 100 + "%" : ""}${
                      method.deposit_rate
                        ? method.deposit_fee
                          ? " + " + method.deposit_fee + "đ"
                          : ""
                        : method.deposit_fee
                        ? method.deposit_fee + "đ"
                        : ""
                    }`
                  }}
                  {{ method.handler === "vnpay-qr" ? " - Mở app ngân hàng để quét mã VNPAY QR" : "" }}
                </p>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="content-container value-container" v-if="selectedMethod.id" :class="{ paddingKeyboad: isApp }">
        <div class="content-title">Số tiền nạp</div>
        <b-card class="text-center" v-if="selectedMethod.handler == 'telco'">
          <div class="payment-box">
            <div class="box-title">Loại thẻ cào</div>
            <div class="payment-child-list d-flex">
              <div
                v-bind:class="{
                  'payment-child': true,
                  selected: selectedChild && child.handler == selectedChild.handler
                }"
                v-for="child in selectedMethod.children"
                :key="'k_' + selectedMethod.id + '_' + child.id"
                @click="selectedChild = { ...child }"
              >
                <div class="img-95-42 img-wrapper h-100">
                  <img class="img-small" :src="child.image_url" alt />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="payment-box">
            <div class="box-title">Mệnh giá</div>
            <MaxGameDropdownPlayList
              :data="playList"
              :isOpen="isOpenPlayList"
              @updateData="updateDataPlayList"
              :itemActived="playListActived"
              @updateModal="updateModalPlayList"
            />
          </div> -->
          <div class="content-payment">
            <p class="payment-title">Tiền cộng tài khoản ví</p>
            <p class="payment-value">
              {{
                formatPrice(parseFloat(playListActived.key * (1 - selectedChild.rate)) + parseFloat(selectedChild.fee))
              }}
            </p>
            <button class="btn btn-danger btn-payment" :disabled="buttonDisabled" @click="createDeposit()">
              TIẾP TỤC
            </button>
          </div>
        </b-card>
        <b-card class="text-center" v-else-if="selectedMethod.handler == 'voucher'">
          <b-form-input
            id="voucher"
            v-model="voucher"
            trim
            :maxlength="16"
            title="Mã voucher"
            placeholder="Nhập mã voucher"
            :state="invalidVoucher"
            @click="handlerScroll"
            aria-describedby="voucher-feedback"
            :formatter="(text) => text.toUpperCase()"
            @change="
              invalidVoucher = null;
              invalidVoucherMsg = '';
            "
          ></b-form-input>
          <b-form-invalid-feedback id="voucher-feedback">{{ invalidVoucherMsg }}</b-form-invalid-feedback>
          <button class="btn btn-payment" :disabled="buttonVoucherDisabled" @click="createVoucherDeposit()">
            TIẾP TỤC
          </button>
        </b-card>
        <b-card class="text-center" v-else>
          <AutoCompleteInput
            ref="deposit_input"
            id="deposit_amount"
            placeholder="Số tiền nạp"
            :specialAmounts="specialAmounts"
            :state="validPaymentValue"
            aria-describedby="deposit-amount-feedback"
            v-on:selected="selectPaymentValue"
            v-on:filter="updatePaymentValue"
            :formatter="formatPaymentValue"
            :click="handlerScroll"
            :options="suggestAmounts"
            :minValue="minValue"
            v-on:error="handlePaymentInputError"
          ></AutoCompleteInput>
          <b-row class="my-3 mx-0">
            <b-col
              v-for="(item, index) of paymentSuggestion"
              :class="{ 'pl-0': index === 0, 'pr-0': true }"
              v-bind:key="item.key"
            >
              <b-button class="btn-light btn-sugges" @click="onSelectSuggestMoney(item.value)">{{ item.key }}</b-button>
            </b-col>
          </b-row>
          <p :class="{ 'text-danger': invalidPaymentValueMsg }" class="money-notice text-italic text-left mt-1">
            Số tiền nạp phải từ 20k và chia hết cho 10k
          </p>
          <div class="groupInfo mt-3">
            <div class="groupInfo__item">
              <span>Tiền nạp</span>
              <span>{{ formatPrice(paymentValueNumber) }}</span>
            </div>
            <div class="groupInfo__item">
              <span>Phí giao dịch</span>
              <span>{{ formatPrice(paymentAmount - paymentValueNumber) }}</span>
            </div>
            <hr class="mt-1 mb-2" />
            <div class="groupInfo__item font-weight-bold">
              <span>Thành tiền</span>
              <span>{{ formatPrice(paymentAmount) }}</span>
            </div>
            <div class="groupInfo__item" v-if="paymentAmount">
              <span class="text-italic money-string">{{ paymentAmountString }}</span>
            </div>
            <button
              id="btnConMomo"
              class="btn btn-danger btn-payment mt-2"
              :disabled="paymentValueNumber === 0 || validPaymentValue === false"
              @click="
                ['vnpay', 'atm'].includes(selectedMethod.handler)
                  ? requestPaymentUrl()
                  : handleOpenModalMomoOnClick(selectedMethod.handler)
              "
            >
              <Spinner
                v-if="loading && ['vnpay', 'atm'].includes(selectedMethod.handler)"
                class="request-url-spinner"
              />
              <p v-else>
                TIẾP TỤC
              </p>
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal
      id="bankModal"
      v-model="modalBank"
      hide-footer
      title="Nạp tiền ví qua chuyển khoản"
      size="lg"
      header-class="modal-bank-header"
      modal-class="modalResp"
      scrollable
    >
      <div class="bank-info d-flex">
        <ul class="bankDescription">
          <li>
            Quý khách
            <b>cần điền số điện thoại đăng ký Thần Tài vào nội dung chuyển khoản.</b>
          </li>
          <li>Chọn Chuyển tiền nhanh để nạp tiền vào ví nhanh hơn.</li>
        </ul>
        <div class="bank-content">
          {{ getPhone
          }}<span class="bank-icon"
            ><img
              height="15"
              src="@/assets/img/icon/icon_copy_primary.svg"
              alt=""
              @click="copyMsgToClipboard(`${getPhone}`)"
              v-if="!copiedMsg"/>
            <img height="15" src="@/assets/img/icon/icon_check.svg" alt="" v-else
          /></span>
        </div>
        <div class="bank-notice float-left">
          <p class="notice-title">Lưu ý:</p>
          <ul class="notice-list">
            <li class="notice-content" v-for="[index, notice] in Object.entries(noticeList)" :key="'notice_' + index">
              &nbsp;{{ notice }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bank-list">
        <p class="bank-list-title">Danh sách tài khoản ngân hàng</p>
        <div v-for="[index, bank] in Object.entries(bankList)" :key="'bank_' + index" class="d-flex bank-list-item">
          <div class="bank-img"><img :src="bank.img_url" alt="" /></div>
          <div class="bank-detail-list">
            <p class="bank-detail">
              Ngân hàng: <span class="bank-detail-bold">{{ bank.bank_name }}</span>
            </p>
            <p class="bank-detail">
              Chủ TK: <span class="bank-detail-bold">{{ bank.account_name }}</span>
              <span class="bank-icon" v-if="!copied[`${index}_account_name`]"
                ><img
                  height="15"
                  src="@/assets/img/icon/icon_copy.svg"
                  alt=""
                  @click="copyToClipboard(index, 'account_name')"
              /></span>
              <span class="bank-copied" v-else>ĐÃ COPY</span>
            </p>
            <p class="bank-detail">
              Số TK: <span class="bank-detail-bold">{{ bank.bank_number }}</span>
              <span class="bank-icon" v-if="!copied[`${index}_bank_number`]"
                ><img
                  height="15"
                  src="@/assets/img/icon/icon_copy.svg"
                  alt=""
                  @click="copyToClipboard(index, 'bank_number')"
              /></span>
              <span class="bank-copied" v-else>ĐÃ COPY</span>
            </p>
            <p class="bank-detail">
              Chi nhánh: <span class="bank-detail-bold">{{ bank.bank_branch }}</span>
            </p>
          </div>
        </div>
        <div id="input_clipboard" />
      </div>
    </b-modal>
    <ModalDeposit :directURL="directURL" />
    <CommonModal
      :title="'Nạp tiền qua Ví MOMO'"
      :isOpen="isOpenModalMomo"
      @updateOpen="handleOpenModalMomo"
      :fullHeight="true"
      :isHiddenFooter="true"
      :modalClass="'customModalClass'"
      @handleHide="handlerHideModalMomo"
    >
      <div class="modalMomo" v-if="step == 1">
        <div class="row pb-4">
          <div class="col-md-7">
            <div class="mb-4 modalMomoBoxTitle">Lưu ý quan trọng</div>
            <p class="mb-4 d-none d-md-block">
              Quý khách vui lòng điền mã giao dịch vào ô “Nhập lời nhắn” khi chuyển tiền trên MOMO.
            </p>
            <p class="mb-4 d-block d-md-none">
              Quý khách vui lòng copy mã giao dịch và dán vào ô “Lời nhắn” khi chuyển tiền trên MOMO.
            </p>
            <p class="mb-4">
              Nếu Quý khách không nhập hoặc nhập sai,
              <span class="text-super-danger">có thể tiền sẽ không được nạp vào tài khoản của Quý khách.</span>
            </p>
          </div>
          <div class="col-md-5">
            <img src="@/assets/img/momo-mobile.png" alt="Momo example mobile" class="mb-3 w-100 d-block d-md-none" />
            <img src="@/assets/img/momo-desktop.png" alt="Momo example mobile" class="mb-3 w-100 d-none d-md-block" />
          </div>
          <div class="col-md-12 momo-footer">
            <b-form-checkbox
              id="checkTerm"
              v-model="checkTerm"
              class="lh-25"
              name="checkTerm"
              :value="true"
              :unchecked-value="false"
            >
              Không hiện lại thông báo này
            </b-form-checkbox>
            <b-button class="mt-1 confirm-momo" :disabled="countDownShow > 0" @click="handleNextMomoStep"
              >TÔI ĐÃ HIỂU {{ countDownShow > 0 ? `(${countDownShow})` : "" }}</b-button
            >
          </div>
        </div>
      </div>
      <div class="modalMomo modalFlex" v-else-if="step == 2">
        <div class="momoContent">
          <div class="momoContent__label mb-1">Mã giao dịch</div>
          <div v-if="paymentCode.momo" class="momoContent__copy mb-4">
            <span class="momoContent__copy--code text-super-danger">
              <CensorData v-if="isHiddenPhone" :data="`${getPhone} ${paymentCode.momo}`" :showLength="9" />
              <span v-else>
                {{ `${getPhone} ${paymentCode.momo}` }}
              </span>
            </span>
            <div class="momoContent__copy--btn d-block d-md-none" @click="copyPhoneToClipboardMomo">
              <img src="@/assets/img/icon/icon_copy_momo.svg" alt="" v-if="!copiedPhoneMomo" />
              <img src="@/assets/img/icon/icon_checkdone_momo.svg" alt="" v-else />
              COPY
            </div>
          </div>
          <div v-else class="mb-4">
            <content-placeholders>
              <content-placeholders-img class="momoContent__copy" />
            </content-placeholders>
          </div>
          <div class="momoContent__qr d-none d-md-block">
            <div class="momoContent__label mb-1">Quét mã để chuyển tiền</div>
            <div class="groupQr" v-if="getDeepLinkMomo">
              <QrcodeVue :value="getDeepLinkMomo" class="text-center" size="190" :level="'H'" />
              <img
                draggable="false"
                class="logoMini"
                src="@/assets/img/logo68.png"
                alt="logo"
                width="24px"
                height="24px"
              />
            </div>
            <div v-else>
              <content-placeholders>
                <content-placeholders-img class="placeholders-momo" />
              </content-placeholders>
            </div>
          </div>
          <div class="modalContent__notice d-block d-md-none">
            <p class="mb-4">
              Quý khách vui lòng copy mã giao dịch và dán vào ô “Lời nhắn” khi chuyển tiền trên MOMO.
            </p>
            <p class="mb-3">
              Nếu Quý khách không nhập hoặc nhập sai,
              <span class="text-super-danger">có thể tiền sẽ không được nạp vào tài khoản của Quý khách.</span>
            </p>
            <img src="@/assets/img/momo-mobile.png" alt="Momo example mobile" class="w-100" />
          </div>
        </div>
        <div class="modalNotice d-none d-md-block">
          <div class="modalNotice__step">
            <div class="modalNotice__step--icon">1</div>
          </div>
          <div class="modalNotice__content">
            <p class="mb-4">Mở ứng dụng MOMO & quét mã chuyển tiền</p>
          </div>
          <div class="modalNotice__step">
            <div class="modalNotice__step--icon">2</div>
          </div>
          <div class="modalNotice__content">
            <p class="mb-3">Điền mã giao dịch vào ô “Nhập lời nhắn” khi chuyển tiền trên MOMO.</p>
            <p class="mb-3">
              Nếu Quý khách không nhập hoặc nhập sai,
              <span class="text-super-danger">có thể tiền sẽ không được nạp vào tài khoản của Quý khách.</span>
            </p>
            <img src="@/assets/img/momo-desktop.png" alt="Momo example mobile" class="w-100" />
          </div>
        </div>
        <b-button class="mt-2 mb-2 redirect-momo d-block d-md-none" @click="handleOpenMomo"
          ><img src="@/assets/img/momo.png" alt="Momo icon" /> Mở ví momo</b-button
        >
        <hr class="mt-0 mb-0 d-block d-md-none" />
        <div class="momoNotice d-block d-md-none">
          <div class="momoNotice__title">Lưu ý</div>
          <div class="momoNotice__content">
            <ul>
              <li v-for="(notice, index) in momoNoticeList" :key="index">
                {{ notice }}
              </li>
            </ul>
          </div>
        </div>
        <div id="input_clipboard_momo" />
      </div>
    </CommonModal>
    <CommonModal
      title="Nạp tiền qua quét mã QR WeScan"
      :isOpen="isOpenModalWescan"
      @updateOpen="handleOpenModalWescan"
      :fullHeight="true"
      :isHiddenFooter="true"
      :modalClass="'customModalClass'"
      @handleHide="handlerHideModalWescan"
    >
      <div class="row">
        <div class="col-md-5 wescanInfo text-center">
          <div class="wescanInfo__text">Số tiền thanh toán</div>
          <div class="wescanInfo__value">{{ formatPrice(paymentAmount) }}</div>
          <div class="groupQr">
            <div v-if="isLoadingQrPayment">
              <content-placeholders>
                <content-placeholders-img class="placeholders-qr" />
              </content-placeholders>
            </div>
            <QrcodeVue v-else id="qrCodePayment" :value="wescanQr" size="250" level="H" class="text-center" />
            <img
              v-if="!isLoadingQrPayment"
              draggable="false"
              class="logoMini"
              src="@/assets/img/logowescan.jpeg"
              alt="logo"
              width="24px"
              height="24px"
            />
          </div>
          <div v-if="!isLoadingQrPayment">
            <div class="wescanInfo__note" v-if="countDownShow > 0">
              Mã thanh toán sẽ hết hạn sau <strong>{{ countDownShow }} giây</strong>
            </div>
            <div class="wescanInfo__note" v-else>
              Mã thanh toán đã hết hạn
            </div>
          </div>
          <div
            v-if="!isLoadingQrPayment"
            class="wescanInfo__download"
            @click="handleDownloadQr('#qrCodePayment > canvas', 'qr_wescan')"
          >
            <img height="15" src="@/assets/img/icon/icon_download.svg" alt="" />Tải mã QR
          </div>
        </div>
        <div class="col-md-7 pl-lg-1 wescanInstruction">
          <div class="wescanInstruction__header">Hướng dẫn thanh toán</div>
          <div class="wescanInstruction__list">
            <ol>
              <li v-for="(item, index) of wescanInstructionList" :key="'wescan_' + index">
                {{ item }}
              </li>
            </ol>
            <div class="wescanInstruction__link" @click="handleOpenModalQRPay(true)">
              Danh sách ngân hàng hỗ trợ QR Pay
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
    <CommonModal
      title="Nạp tiền qua quét mã QR VNPay"
      :isOpen="isOpenModalVnpay"
      @updateOpen="handleOpenModalVnPay"
      :fullHeight="true"
      :isHiddenFooter="true"
      :modalClass="'customModalClass'"
      @handleHide="handlerHideModalVnPay"
    >
      <div class="row">
        <div class="col-md-5 wescanInfo text-center">
          <div class="wescanInfo__text">Số tiền thanh toán</div>
          <div class="wescanInfo__value">{{ formatPrice(paymentAmount) }}</div>
          <div class="groupQr">
            <div v-if="isLoadingQrPayment">
              <content-placeholders>
                <content-placeholders-img class="placeholders-qr" />
              </content-placeholders>
            </div>
            <QrcodeVue v-else id="qrCodePaymentVNPay" :value="vnPayQr" size="250" level="H" class="text-center" />
          </div>
          <div v-if="!isLoadingQrPayment">
            <div class="wescanInfo__note" v-if="countDownShow > 0">
              Mã thanh toán sẽ hết hạn sau <strong>{{ countDownShow }} giây</strong>
            </div>
            <div class="wescanInfo__note" v-else>
              Mã thanh toán đã hết hạn
            </div>
            <div class="wescanInfo__download" @click="handleDownloadQr('#qrCodePaymentVNPay > canvas', 'qr_vnpay')">
              <img height="15" src="@/assets/img/icon/icon_download.svg" alt="" />Tải mã QR
            </div>

            <div v-if="showCheckBalance" class="d-flex align-items-center flex-column text-center mt-3">
              <div v-if="isCheckedPayment" class="wescanInfo__note">
                Có thể giao dịch đang được xử lý, vui lòng đợi trong vài phút và kiểm tra lại.
              </div>
              <div v-else class="wescanInfo__note">
                Bạn đã thanh toán nhưng chưa thấy tiền vào tài khoản?
              </div>
              <span
                v-if="!isLoadingCheckPayment"
                class="m-text-secondary font-weight-600 pointer mt-2"
                @click="handleCheckPaymentRequest"
                >KIỂM TRA SỐ DƯ</span
              >
              <b-spinner v-else small class="mt-2"></b-spinner>
            </div>
          </div>
        </div>
        <div class="col-md-7 pl-lg-1 wescanInstruction">
          <div class="wescanInstruction__header">Hướng dẫn thanh toán</div>
          <div class="wescanInstruction__list">
            <ol>
              <li v-for="(item, index) of wescanInstructionList" :key="'wescan_' + index">
                {{ item }}
              </li>
            </ol>
            <div class="wescanInstruction__link" @click="handleOpenModalQRPay(true)">
              Danh sách ngân hàng hỗ trợ QR Pay
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
    <ModalListBankSupportQRPay
      :isOpenModalQRPay="isOpenModalQRPay"
      @handleOpenModalQRPay="handleOpenModalQRPay"
      @handleHideModalQRPay="handlerHideModalQRPay"
    />
    <CommonModal
      :title="'Nạp tiền thành công'"
      :isOpen="isOpenPopupDepositSuccess"
      @updateOpen="isOpenPopupDepositSuccess = $event"
      confirmTextBtn="Mua vé"
      cancleTextBtn="Chi tiết"
      @handleCancle="$router.push(dataDeposit.target_url || '/')"
      @handleConfirm="$router.push('/')"
    >
      <div>
        <div class="text-center">
          <img src="@/assets/img/icon/icon_result-success.svg" alt="img succes" width="60px" height="60px" />
          <div class="mt-3">
            Tài khoản ví đã được nạp thêm
            <b>{{ dataDeposit.amount ? formatPrice(dataDeposit.amount) : "" }}</b>
          </div>
        </div>
      </div>
    </CommonModal>
  </div>
</template>

<script>
import PaymentService from "@/api/payment";
import AccountService from "@/api/account";
import Spinner from "@/components/Spinner.vue";
import ModalDeposit from "./ModalDeposit";
import QrcodeVue from "qrcode.vue";
const BaseURL = window.location.origin;
// import MaxGameDropdownPlayList from '@/components/MaxGame/MaxGameDropdownPlayList';
import ModalListBankSupportQRPay from "@/components/Payment/ModalListBankSupportQRPay";
import CommonModal from "../CommonModal";
import { EMAIL_SUPPORT, SOCKET_EVENT } from "../../common/global";
import { Noti } from "../../main";
const DEFAULT_EXPIRED_TIME = 5; // minute
const DEFAULT_SHOW_CHECK_BALANCE = 1; // minute
import { VNPayGatewayError } from "@/api/mock/errors";
import AutoCompleteInput from "@/components/AutoCompleteInput";
import n2vw from "@/utils/number2words";
import _ from "lodash";
import CensorData from "@/components/CensorData";
import { isMobile } from "mobile-device-detect";

const MAX_SUGGEST_AMOUNT = 10000000;
const MIN_AMOUNT = 20000;
const MAX_AMOUNT = 100000000;
const converter = new n2vw();
export default {
  layouts: "Deposit",
  components: {
    AutoCompleteInput,
    Spinner,
    // MaxGameDropdownPlayList,
    ModalDeposit,
    CommonModal,
    // NumberItem,
    QrcodeVue,
    ModalListBankSupportQRPay,
    CensorData
  },
  data() {
    return {
      specialAmounts: [21000],
      isMobile: isMobile,
      paymentSuggestion: [
        { key: "100k", value: 100000 },
        { key: "200k", value: 200000 },
        { key: "500k", value: 500000 }
      ],
      minValue: MIN_AMOUNT,
      selectedMethod: {
        id: null,
        fee: 0
      },
      isLoading: true,
      loading: false,
      paymentMethods: [],
      paymentValue: "",
      validPaymentValue: null,
      invalidPaymentValueMsg: "",
      paymentTerm: false,
      playList: [10000, 20000, 30000, 50000, 100000, 200000, 300000, 500000].map((value) =>
        this.getPlaylistValue(value)
      ),
      playListMomo: [20000, 50000, 100000, 200000, 500000, 1000000].map((value) => this.getPlaylistValue(value)),
      isOpenPlayList: false,
      playListActived: { key: 100000, value: this.formatPrice(100000), isActive: true },
      playListMomoActived: { key: 100000, value: this.formatPrice(100000), isActive: true },
      selectedChild: {},
      paymentProcessing: false,
      modalBank: false,
      bankList: [
        {
          img_url: "https://d1aleksa3dshq6.cloudfront.net/banks/vcb.png",
          bank_name: "Vietcombank",
          account_name: "CÔNG TY CỔ PHẦN GIẢI TRÍ SỐ WELUCK",
          bank_number: "1029457141",
          bank_branch: "Đông Sài Gòn - HCM"
        },
        {
          img_url: "https://d1aleksa3dshq6.cloudfront.net/banks/vtb.png",
          bank_name: "Vietinbank",
          account_name: "CÔNG TY CỔ PHẦN GIẢI TRÍ SỐ WELUCK",
          bank_number: "114002914513",
          bank_branch: "Công Thương chi nhánh 2 HCM"
        }
      ],
      noticeList: [
        "Số tiền tối thiểu mỗi lần chuyển khoản: 50.000đ, chia hết cho 10.000",
        "Thời gian xử lý: trong khoảng 2 giờ làm việc kể từ khi nhận được tiền."
      ],
      vnpayInstructionList: [
        "Mở ứng dụng ví <b>VNPAY</b>",
        "Truy cập tính năng quét mã <b>QR Pay</b>",
        "Quét mã QR và nhập cú pháp bên dưới trong ô nội dung và thực hiện chuyển tiền"
      ],
      wescanInstructionList: [
        "Đăng nhập ứng dụng Mobile Banking, chọn chức năng QRPay và quét mã QR.",
        "Kiểm tra thông tin đơn hàng và lựa chọn tài khoản thanh toán.",
        "Xác nhận thanh toán và hoàn tất giao dịch."
      ],
      vnpayNoticeList: [
        "Giao dịch sẽ tự động hoàn thành khi bạn chuyển tiền. Thời gian hoàn thành giao dịch trong khoảng 15 giây tới tối đa 5 phút, kể từ lúc bạn chuyển tiền xong.",
        `Nếu sau 5 phút kể từ lúc chuyển tiền tài khoản vẫn chưa được nạp, vui lòng liên hệ hỗ trợ hoặc gửi mail với ảnh chụp lệnh chuyển tiền tới ${EMAIL_SUPPORT ||
          "cskh@thantai68.net"} với để được hỗ trợ trực tiếp.`
      ],
      momoNoticeList: [
        "Giao dịch sẽ tự động hoàn thành khi bạn chuyển tiền. Thời gian hoàn thành giao dịch trong khoảng 15 giây tới tối đa 5 phút, kể từ lúc bạn chuyển tiền xong.",
        `Nếu sau 5 phút kể từ lúc chuyển tiền tài khoản vẫn chưa được nạp, vui lòng liên hệ hỗ trợ hoặc gửi mail với ảnh chụp lệnh chuyển tiền tới ${EMAIL_SUPPORT ||
          "cskh@thantai68.net"} với để được hỗ trợ trực tiếp.`
      ],
      copied: {},
      copiedMsg: false,
      copiedPhoneMomo: false,
      copiedPhoneMomoDisable: false,
      timeout: null,
      voucher: null,
      invalidVoucher: null,
      invalidVoucherMsg: "",
      directURL: "",
      isOpenModalMomo: false,
      step: 2,
      checkTerm: false,
      isOpenModalVnpay: false,
      paymentCode: {},
      countDown: null,
      countDownShow: 4,
      isOpenModalWescan: false,
      isOpenModalQRPay: false,
      wescanQr: "",
      isLoadingQrPayment: false,
      vnPayQr: "",
      isHiddenPhone: false,
      showCheckBalance: false,
      countDownCheckBalance: null,
      paymentRequestId: null,
      isCheckedPayment: false,
      isLoadingCheckPayment: false,
      dataDeposit: {
        amount: 0,
        target_url: ""
      },
      isOpenPopupDepositSuccess: false
    };
  },
  beforeCreate() {
    if (Object.keys(this.$route.query).length > 2) {
      this.paymentProcessing = true;
      PaymentService.verifyPayment(this.$route.query)
        .then((res) => {
          if (res.data.status == true) {
            let redirectRoute = "/tai-khoan/lich-su-nap-tien/" + res.data.data.display_deposit_id;
            if (this.$route.query.order == 1)
              if (res.data.data.deposit_status == 1) {
                redirectRoute = "/thanh-toan-keno/1?status=1";
                this.$store.commit("setOrderTab", 1);
              } else redirectRoute = "/thanh-toan-keno/1?status=0";
            this.$router.replace(redirectRoute);
          } else {
            this.showErrorMessage();
          }
        })
        .catch(() => {
          this.showErrorMessage();
        })
        .finally(() => {
          this.paymentProcessing = false;
        });
    }
  },
  created() {
    if (this.$route.query.errorCode) {
      this.handleErrorPostback(this.$route.query.errorCode);
    }
    this.getPaymentMethods();
    this.step = this.userSettings.term_momo ? 2 : 1;

    this.getConfig();
  },
  sockets: {
    [SOCKET_EVENT.DEPOSIT_PAYMENT]: function(data) {
      const dataParse = JSON.parse(data);
      if (dataParse.success) {
        this.handleOpenModalWescan(false);
        this.handleOpenModalVnPay(false);
      }
    }
  },
  methods: {
    onUnSelectPaymentMethod() {
      this.selectedMethod = {
        id: null,
        fee: 0
      };
    },
    onSelectSuggestMoney(value) {
      this.$refs.deposit_input.updateTextValue(value);
      this.paymentValue = value;
    },
    handlePaymentInputError(message) {
      if (message) {
        this.validPaymentValue = false;
        this.invalidPaymentValueMsg = message;
      }
    },
    updatePaymentValue(value) {
      this.paymentValue = value || "";
    },
    selectPaymentValue(value) {
      this.paymentValue = value.key || "";
    },
    handleErrorPostback(errorCode) {
      const error = VNPayGatewayError[errorCode] || VNPayGatewayError.default;
      Noti.fire({
        icon: "error",
        title: `${error.message}\nMã lỗi: [${errorCode}]`,
        showConfirmButton: false
      });
      this.$router.replace(this.$route.path);
    },
    getPlaylistValue(value) {
      return { key: value, value: this.formatPrice(value), isActive: true };
    },
    countDownTerm() {
      if (this.countDown || this.countDownShow <= 0) {
        return;
      }
      let _this = this;
      this.countDown = setInterval(function() {
        if (_this.countDownShow <= 0) {
          clearInterval(_this.countDown);
          _this.countDown = null;
        }
        _this.countDownShow = _this.countDownShow - 1;
      }, 1000);
    },
    handleNextMomoStep() {
      if (this.checkTerm) {
        this.patchSaveUserSettings();
      }
      this.step = 2;
      document.querySelector(".modalMomo").scrollIntoView({ behavior: "smooth" });
    },
    handlerScroll() {
      // if (this.isOnlyMobile) {
      //   setTimeout(() => {
      //     document.getElementById('idReward').scrollIntoView({
      //       behavior: 'smooth'
      //     });
      //   }, 300);
      // }
    },
    patchSaveUserSettings() {
      AccountService.patchSaveUserSettings(this.accountId, { term_momo: 1 }).then((result) => {
        if (result.status == 204) {
          this.userSettings = { ...this.userSettings, term_momo: 1 };
        }
      });
    },
    getPaymentCode(handler) {
      PaymentService.getPaymentCode(this.accountId, handler)
        .then((result) => {
          if (result.data.status) {
            this.paymentCode = {
              ...this.paymentCode,
              [handler]: result.data.data.code
            };
          }
        })
        .catch(() => {
          this.isOpenModalMomo = false;
          Noti.fire({
            icon: "error",
            title: `Đã có lỗi xảy ra, vui lòng thử lại.`,
            showConfirmButton: false,
            timer: 2000
          });
        });
    },
    handleOpenModalMomoOnClick(handler) {
      switch (handler) {
        case "momo":
          this.handleOpenModalMomo(true);
          break;
        case "vnpay-qr":
          this.handleOpenModalVnPay(true);
          break;
        case "wescan-qr":
          this.handleOpenModalWescan(true);
          break;
        default:
          break;
      }
    },
    handleOpenModalMomo(value) {
      window.dataLayer.push({
        event: "click-payment-method",
        phoneNumber: this.getPhone ? this.getPhone : "",
        paymentMethod: "Ví Momo"
      });
      this.isOpenModalMomo = value;
      this.countDownShow = 4;
      this.countDownTerm();
      if (!this.paymentCode.momo) this.getPaymentCode("momo");
    },
    handleOpenModalWescan(value) {
      this.isOpenModalWescan = value;
      if (value) {
        window.dataLayer.push({
          event: "click-payment-method",
          phoneNumber: this.getPhone ? this.getPhone : "",
          paymentMethod: "WeScan"
        });
        this.getWescanQr();
      } else {
        this.handleClearTimer();
      }
    },
    async getWescanQr() {
      // if (!this.isConnectedSocket) {
      //   this.handleOpenModalWescan(false);
      //   Noti.fire({
      //     icon: 'error',
      //     title: 'Lỗi máy chủ nội bộ, vui lòng thử lại sau !',
      //     showConfirmButton: false,
      //     timer: 2000
      //   });
      //   return;
      // }

      this.isLoadingQrPayment = true;
      let expiredTime = new CustomDate(
        new CustomDate().setMinutes(new CustomDate().getMinutes() + DEFAULT_EXPIRED_TIME)
      );
      const params = {
        account_id: this.accountId,
        amount: this.paymentAmount,
        method_handler: this.selectedMethod.handler,
        expired_time: expiredTime.toISOString(),
        client_id: this.$socket.id
      };
      let paymentQr = await PaymentService.getPaymentQr(params);
      this.countDownShow = DEFAULT_EXPIRED_TIME * 60;
      if (paymentQr.status) {
        this.wescanQr = paymentQr.data.qr_code;
        this.countDownTerm();
        this.paymentRequestId = paymentQr.data.payment_request_id;
      } else {
        this.handleOpenModalWescan(false);
        Noti.fire({
          icon: "error",
          title: `Đã có lỗi xảy ra, vui lòng thử lại`,
          showConfirmButton: false,
          timer: 2000
        });
      }
      this.isLoadingQrPayment = false;
      this.handleFailSocket();
    },
    handleOpenModalVnPay(value) {
      this.isOpenModalVnpay = value;
      if (value) {
        window.dataLayer.push({
          event: "click-payment-method",
          phoneNumber: this.getPhone ? this.getPhone : "",
          paymentMethod: "QR VNPay"
        });

        this.getVNPayQr();
      } else {
        this.handleClearTimer();
      }
    },
    async getVNPayQr() {
      // if (!this.isConnectedSocket) {
      //   this.handleOpenModalVnPay(false);
      //   Noti.fire({
      //     icon: 'error',
      //     title: 'Lỗi máy chủ nội bộ, vui lòng thử lại sau !',
      //     showConfirmButton: false,
      //     timer: 2000
      //   });
      //   return;
      // }
      this.isLoadingQrPayment = true;
      let expiredTime = new CustomDate(
        new CustomDate().setMinutes(new CustomDate().getMinutes() + DEFAULT_EXPIRED_TIME)
      );
      const params = {
        account_id: this.accountId,
        amount: this.paymentAmount,
        method_handler: this.selectedMethod.handler,
        expired_time: expiredTime.toISOString(),
        client_id: "" //this.$socket.id
      };
      let paymentQr = await PaymentService.getPaymentQr(params);
      this.countDownShow = DEFAULT_EXPIRED_TIME * 60;
      if (paymentQr.status) {
        this.vnPayQr = paymentQr.data.qr_code;
        this.paymentRequestId = paymentQr.data.payment_request_id;
        this.countDownTerm();
      } else {
        this.handleOpenModalVnPay(false);
        Noti.fire({
          icon: "error",
          title: `Đã có lỗi xảy ra, vui lòng thử lại`,
          showConfirmButton: false,
          timer: 2000
        });
      }
      this.isLoadingQrPayment = false;
      this.handleFailSocket();
    },
    handleOpenModalQRPay(value) {
      this.isOpenModalQRPay = value;
    },
    handlerHideModalWescan() {
      this.showCheckBalance = false;
      this.countDownCheckBalance = null;
      this.paymentRequestId = null;
      this.isLoadingCheckPayment = false;
      this.isCheckedPayment = false;
    },
    handlerHideModalQRPay() {
      // console.log('lul');
    },
    handlerHideModalVnPay() {
      this.showCheckBalance = false;
      this.countDownCheckBalance = null;
      this.paymentRequestId = null;
      this.isLoadingCheckPayment = false;
      this.isCheckedPayment = false;
    },
    handlerHideModalMomo() {
      this.copiedPhoneMomoDisable = false;
    },
    createVoucherDeposit() {
      if (this.voucher == null || this.voucher == "") {
        this.invalidVoucher = false;
        this.invalidVoucherMsg = "Voucher không hợp lệ.";
        return;
      }
      window.dataLayer.push({
        event: "click-payment-method",
        phoneNumber: this.getPhone ? this.getPhone : "",
        paymentMethod: this.selectedMethod.name
      });
      PaymentService.applyVoucher(this.accountId, this.voucher.toUpperCase())
        .then((resp) => {
          if (resp.data.status == true) {
            Noti.fire({
              icon: "success",
              title: `Nạp tiền thành công!`,
              text: `Tài khoản đã được cộng thêm ${this.formatPrice(resp.data.data.amount)}`,
              showConfirmButton: false,
              // confirmButtonColor: '#e8a500',
              timer: 4000
            });

            this.refreshBalances();
          }
        })
        .catch((error) => {
          let message = "Đã có lỗi xảy ra, vui lòng thử lại.";
          if (error.response.data) {
            message = error.response.data.data.message || message;
            if (error.response.data.data.attempts < 5)
              message += ` Còn lại ${5 - error.response.data.data.attempts} lượt thử.`;
          }
          Noti.fire({
            icon: "error",
            title: `Nạp tiền thất bại!`,
            text: message,
            showConfirmButton: false,
            timer: 2000
          });
        })
        .finally(() => {
          this.voucher = null;
          this.invalidVoucher = null;
          this.invalidVoucherMsg = "";
        });
    },
    copyTextToClipboard(text, selectorID = "input_clipboard") {
      let textArea = document.createElement("textArea");
      var range, selection;
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.getElementById(selectorID).appendChild(textArea);
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        // iOS
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else textArea.select();
      document.execCommand("copy");
      document.getElementById(selectorID).removeChild(textArea);
    },
    copyMsgToClipboard(text) {
      this.copiedMsg = true;
      this.copyTextToClipboard(text);
      this.timeout = setTimeout(() => {
        this.copiedMsg = false;
      }, 2000);
    },
    copyToClipboard(index, key, value = null, selectorID = "input_clipboard") {
      clearTimeout(this.timeout);

      // copy to clipboard
      if (value) text = value;
      else var text = this.bankList[index][key];
      this.copyTextToClipboard(text, selectorID);

      // animation
      this.copied = {};
      this.copied[`${index}_${key}`] = true;
      this.timeout = setTimeout(() => {
        this.copied = {};
      }, 2000);
    },
    updateDataPlayList(newObj) {
      this.playListActived = newObj;
    },
    updateDataPlayListMomo(newObj) {
      this.playListMomoActived = newObj;
    },
    updateModalPlayList(value) {
      this.isOpenPlayList = value;
    },
    formatNumberFromString(string) {
      const number = string.split(".").join("");
      if (isNaN(parseFloat(number))) {
        return null;
      }
      return parseFloat(number);
    },
    getPaymentMethods() {
      PaymentService.getPaymentMethods()
        .then((res) => {
          if (res.data.status == true) {
            this.isLoading = false;
            this.paymentMethods = [
              ...res.data.data
                .filter(
                  (payment) =>
                    payment.allow_deposit == 1 &&
                    payment.handler != "vnpayewallet" &&
                    payment.handler != "vnpay" &&
                    payment.handler != "vnpay-qr" &&
                    payment.handler != "wescan-qr" &&
                    payment.handler != "atm"
                )
                .sort((a, b) => a.deposit_priority - b.deposit_priority)
            ];
            // this.selectedMethod = this.paymentMethods && this.paymentMethods.length > 0 ? this.paymentMethods[0] : null;
            if (this.selectedMethod.children && this.selectedMethod.children.length > 0) {
              this.selectedChild = { ...this.selectedMethod.children[0] };
              this.paymentValue = "";
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    formatPaymentValue(value) {
      this.validPaymentValue = null;
      this.invalidPaymentValueMsg = "";
      if (this.formatNumberFromString(value.toString().trim()) > MAX_AMOUNT) {
        return this.formatPrice(MAX_AMOUNT, "");
      }
      if (value != "") {
        value = this.formatPrice(this.formatNumberFromString(value.toString().trim()), "");
      }

      return value;
    },
    selectMethod(method) {
      if (method.handler == "bank") {
        window.dataLayer.push({
          event: "click-payment-method",
          phoneNumber: this.getPhone ? this.getPhone : "",
          paymentMethod: method.name
        });
        this.modalBank = true;
        return;
      }
      this.selectedMethod = { ...method };
      if (method.children && method.children.length > 0) {
        this.selectedChild = { ...method.children[0] };
        this.paymentValue = "";
      }
      setTimeout(() => {
        if (method.handler == "voucher") {
          document.getElementById("voucher").focus();
          this.handlerScroll();
        }
      }, 1);
    },
    requestPaymentUrl: _.debounce(function() {
      window.dataLayer.push({
        event: "click-payment-method",
        phoneNumber: this.getPhone ? this.getPhone : "",
        paymentMethod: this.selectedMethod.name
      });
      this.loading = true;
      // Validate payment method
      if (this.selectedMethod == null) {
        this.loading = false;
        return;
      }
      let expiredTime = new CustomDate(
        new CustomDate().setMinutes(new CustomDate().getMinutes() + DEFAULT_EXPIRED_TIME)
      );

      let windowReference;

      if (this.isSafari) {
        windowReference = window.open();
      }

      function showErrorMessage() {
        if (windowReference) {
          windowReference.close();
        } else {
          this.showErrorMessage();
        }
      }

      PaymentService.requestPaymentUrl({
        amount: this.paymentAmount,
        method_handler: this.selectedMethod.handler,
        account_id: this.accountId,
        redirect_base_url: window.location.origin,
        is_deeplink: false, //!!window.ReactNativeWebView,
        expired_time: expiredTime.toISOString()
      })
        .then((res) => {
          if (res?.data?.status == true) {
            const url = res?.data?.data?.url;
            if (!url) {
              showErrorMessage();
              return;
            }

            if (windowReference) {
              windowReference.location = url;
            } else {
              if (window.ReactNativeWebView) {
                this.postMessage("OPEN_DEEP_LINK", { url });
              } else {
                window.open(url, "_blank");
              }
            }
          } else {
            showErrorMessage();
          }
        })
        .catch(() => {
          showErrorMessage();
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),
    createDeposit() {
      window.dataLayer.push({
        event: "click-payment-method",
        phoneNumber: this.getPhone ? this.getPhone : "",
        paymentMethod: this.selectedMethod.name
      });
      this.loading = true;
      let paymentValue = "";
      let methodId = this.selectedMethod.id;

      // Validate payment method
      if (this.selectedMethod == null) {
        this.loading = false;
        return;
      }
      let redirectUrl = this.$route.query.order == 1 ? "/tai-khoan/nap-tien?order=1&" : "/tai-khoan/nap-tien?";
      let fee = 0;
      let totalValue = 0;
      if (this.selectedMethod.handler == "telco") {
        methodId = this.selectedChild.id;
        paymentValue = parseFloat((1 - this.selectedChild.rate) * this.playListActived.key) - this.selectedChild.fee;
        fee = parseFloat(this.selectedChild.rate * this.playListActived.key) + this.selectedChild.fee;
        totalValue = this.playListActived.key;
      } else {
        paymentValue = this.formatNumberFromString(this.paymentValue || "0");
        // Validate number
        if (paymentValue == 0 || isNaN(paymentValue)) {
          this.validPaymentValue = false;
          this.invalidPaymentValueMsg = "Vui lòng nhập số tiền cần nạp.";
          this.loading = false;
          return;
        }
        fee = parseFloat(paymentValue * this.selectedMethod.rate) + parseFloat(this.selectedMethod.fee);
        totalValue = paymentValue + fee;
      }
      PaymentService.requestPayment(
        methodId,
        paymentValue,
        fee,
        totalValue,
        `${BaseURL}${redirectUrl}method=${methodId}`,
        `${BaseURL}${redirectUrl}method=${methodId}`
      )
        .then((res) => {
          if (res.data.status == true) {
            this.showModalDeposit(res.data.data.request_url);
          }
        })
        .catch(() => {
          this.showErrorMessage();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalDeposit(url) {
      this.directURL = url;
      this.$store.commit("setModalDeposit", true);
    },
    showErrorMessage() {
      Noti.fire({
        icon: "error",
        title: `Nạp tiền thất bại!`,
        text: "Vui lòng thử lại",
        width: 500,
        confirmButtonText: "Đã hiểu",
        showConfirmButton: true
      }).then((res) => {
        if (res.value) this.$router.replace(this.$route.path);
      });
    },
    copyPhoneToClipboardMomo() {
      this.copiedPhoneMomo = true;
      this.copiedPhoneMomoDisable = true;
      this.copyTextToClipboard(`${this.getPhone} ${this.paymentCode.momo}`, "input_clipboard_momo");
      this.timeout = setTimeout(() => {
        this.copiedPhoneMomo = false;
      }, 2000);
    },
    handleOpenVnpay() {
      window.dataLayer.push({
        event: "click-payment-method",
        phoneNumber: this.getPhone ? this.getPhone : "",
        paymentMethod: "Mở App VNPay"
      });
      const url = "f5smartaccount://";
      if (window.ReactNativeWebView) {
        this.sendMessageToApp(JSON.stringify({ event: "OPEN_DEEP_LINK", data: { url } }));
      } else {
        window.open(url, "_blank");
      }
    },
    handleOpenMomo() {
      window.dataLayer.push({
        event: "click-payment-method",
        phoneNumber: this.getPhone ? this.getPhone : "",
        paymentMethod: "Mở App Momo"
      });
      const url = this.getDeepLinkMomo;
      if (window.ReactNativeWebView) {
        this.postMessage("OPEN_DEEP_LINK", { url: this.generateDeepLink(url) });
      } else {
        window.open(url, "_blank");
      }
    },
    handleClearTimer() {
      clearInterval(this.countDown);
      this.countDown = null;
    },
    methodlinkToDeepLink(link) {
      this.linkToDeepLink(link);
    },
    getConfig() {
      this.$store
        .dispatch("getConfig", `https://assets-vl.s3.ap-southeast-1.amazonaws.com/config.json`)
        .then((res) => {
          if (!res || res.status != 200) {
            return;
          }
          const { wescan_streamer_list } = res.data || {};

          if (wescan_streamer_list.length && wescan_streamer_list.includes(this.accountId)) {
            this.isHiddenPhone = true;
          } else {
            this.isHiddenPhone = false;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleFailSocket() {
      if (this.countDownCheckBalance) {
        clearTimeout(this.countDownCheckBalance);
      }

      this.countDownCheckBalance = setTimeout(() => {
        this.showCheckBalance = true;
      }, 60 * 1000 * DEFAULT_SHOW_CHECK_BALANCE);
      // }
    },
    handleCheckPaymentRequest() {
      this.isLoadingCheckPayment = true;
      this.handleCheckPaymentRequestDebouce();
    },
    handleCheckPaymentRequestDebouce: _.debounce(function() {
      return PaymentService.getPaymentRequestInfo(this.paymentRequestId)
        .then((data) => {
          if (data.data.status) {
            this.dataDeposit = {
              amount: data.data.amount,
              target_url: `/tai-khoan/lich-su-nap-tien/${data.data.display_deposit_id}`
            };
            this.handleOpenModalWescan(false);
            this.handleOpenModalVnPay(false);
            this.refreshBalances();
          }
        })
        .finally(() => {
          this.isLoadingCheckPayment = false;
          this.isCheckedPayment = true;
        });
    }, 300)
  },
  computed: {
    paymentValueNumber() {
      if (!this.paymentValue) {
        return 0;
      }
      if (typeof this.paymentValue === "string") {
        return this.formatNumberFromString(this.paymentValue.toString().trim());
      }
      return this.paymentValue;
    },
    suggestAmounts() {
      if (!this.paymentValue) {
        return [];
      }
      const value = parseInt(this.paymentValue.toString().replace(/\./g, ""));
      let count =
        MAX_SUGGEST_AMOUNT.toString().length - value.toString().length > 0
          ? MAX_SUGGEST_AMOUNT.toString().length - value.toString().length
          : 1;

      let arr = new Array(count).fill(0).map((item, index) => {
        const suggestAmount = `${value}${Array(index + 1)
          .fill("0")
          .join("")}`;

        return {
          key: parseInt(suggestAmount),
          name: this.formatPrice(suggestAmount, "")
        };
      });

      arr = arr.filter((item) => item.key >= MIN_AMOUNT && item.key <= MAX_SUGGEST_AMOUNT && item.key % 10000 === 0);

      return arr;
    },
    paymentAmount() {
      const { deposit_calc_type, deposit_fee, deposit_rate } = this.selectedMethod;
      if (deposit_calc_type === "net-calc") {
        return this.paymentValueNumber * (1 + deposit_rate) + deposit_fee;
      }
      return this.paymentValueNumber;
    },
    paymentAmountString() {
      let string = converter.getFullText(this.paymentAmount);
      return string[0].toUpperCase() + string.substring(1) + " đồng";
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    buttonDisabled: function() {
      return (!this.paymentTerm && this.selectedMethod.handler != "telco") || this.loading;
    },
    buttonVoucherDisabled: function() {
      return !this.voucher || this.loading;
    },
    getPhone() {
      return this.$store.getters.getterUserInfo.phone;
    },
    metadata() {
      const { metadata } = this.selectedMethod;
      if (!metadata) return;
      return JSON.parse(metadata);
    },
    vnpayPhone() {
      return this.metadata?.phone || "";
    },
    getQr() {
      if (!this.metadata) {
        return "";
      }
      // const { VUE_APP_MOMO_PHONE_NUMBER, VUE_APP_MOMO_DISPLAY_NAME, VUE_APP_MOMO_EMAIL } = process.env;
      // return `2|99|${VUE_APP_MOMO_PHONE_NUMBER}|${VUE_APP_MOMO_DISPLAY_NAME}|${VUE_APP_MOMO_EMAIL}|0|0|${this.playListMomoActived.key}`;

      const { qrcode_format } = this.metadata;
      if (typeof qrcode_format == "object" && Object.keys(qrcode_format).length > 0) {
        return qrcode_format[this.paymentAmount];
      }
      if (qrcode_format) {
        return qrcode_format.replace("[amount]", this.paymentAmount);
      }
      return "";
    },
    getDeepLinkMomo() {
      if (!this.metadata || !this.paymentCode?.momo) return "";

      // const { VUE_APP_MOMO_PHONE_NUMBER } = process.env;
      // return `https://nhantien.momo.vn/${VUE_APP_MOMO_PHONE_NUMBER}/${this.playListMomoActived.key}`;
      const { payment_url_format } = this.metadata;

      return payment_url_format
        ?.replace("[amount]", this.paymentAmount)
        ?.replace("[message]", `${this.getPhone} ${this.paymentCode.momo}`);
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    userSettings: {
      get() {
        return this.$store.getters.getterUserSettings;
      },
      set(newVal) {
        this.$store.commit("setUserSettings", newVal);
      }
    },
    isConnectedSocket() {
      return this.$store.getters.getterIsConnectedSocket;
    }
  },
  beforeDestroy() {
    this.copiedPhoneMomoDisable = false;
  },
  watch: {
    userSettings: function(val) {
      this.step = val.term_momo ? 2 : 1;
    },
    countDownShow: function(newVal) {
      if (newVal > 0 || !newVal) return;
      if (!this.isLoadingQrPayment) {
        switch (this.selectedMethod.handler) {
          case "wescan-qr":
            this.getWescanQr();
            this.handlerHideModalWescan();
            break;
          case "vnpay-qr":
            this.getVNPayQr();
            this.handlerHideModalVnPay();
            break;

          default:
            break;
        }
      }
    },
    "$route.query.errorCode": function(newVal) {
      if (newVal) {
        this.handleErrorPostback(newVal);
      }
    },
    modalBank: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>
<style lang="scss">
.btn-suggest {
  border-radius: 3px;
  border: solid 1px #efefef;
  background-color: #ffffff;
  font-size: 1rem;
  color: #333333;
}
.money-notice {
  font-size: 0.825rem;
  color: #666666;
}
.request-url-spinner {
  span {
    color: #fff !important;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.groupInfo {
  &__item {
    min-height: 28px;
    display: flex;
    justify-content: space-between;

    .money-string {
      color: #666666;
      text-align: center;
      width: 100%;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, "lg")) {
  .wescanInstruction {
    margin-top: 60px;
  }
  .bank {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .wescanInfo {
    &__download {
      width: 250px;
    }
  }
  .modalFlex {
    flex-direction: row !important;
  }
  .momoContent {
    min-width: 260px;
    padding: 20px 35px !important;
    text-align: center;
  }

  .modalNotice {
    display: grid !important;
    grid-template-columns: 50px 1fr;
    &__step {
      padding: 0 10px;
      &--icon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        color: #fff;
        background-color: #d31904;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
      }
    }
    &__content {
      font-style: normal;
    }
  }
}
.wescanInfo {
  &__text {
    font-size: 15px;
    line-height: 1;
    text-align: center;
    color: #666666;
  }
  &__value {
    font-size: 25px;
    font-weight: bold;
    line-height: 0.8;
    text-align: center;
    color: #00509d;
    padding: 10px;
    margin-bottom: 5px;
  }
  &__note {
    font-size: 15px;
    font-style: italic;
    line-height: 1.33;
    text-align: center;
    color: #333333;
    margin-top: 5px;
  }
  &__download {
    width: 155px;
    height: 40px;
    margin: 20px auto 0;
    padding: 10px 26px;
    border-radius: 3px;
    background-color: #e5edf5;
    color: #00509d;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    img {
      margin: 0 5px 3px 0;
    }
    cursor: pointer;
  }
}
.wescanInstruction {
  margin-top: 30px;
  &__header {
    font-size: 20px;
    line-height: 1;
    text-align: left;
    color: #333333;
    margin-bottom: 10px;
  }
  &__list {
    ol {
      li {
        list-style-type: decimal;
        font-size: 15px;
        line-height: 1.73;
        text-align: left;
        color: #333333;
        &::marker {
          width: 25px;
        }
      }
    }
    padding-left: 15px;
  }
  &__link {
    font-size: 15px;
    line-height: 1.33;
    text-align: left;
    color: #00509d;
    cursor: pointer;
    margin-top: 15px;
  }
}
.bank {
  width: 100%;
  &__title {
    font-size: 15px;
    text-align: left;
    color: #666666;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    padding: 20px 0;
  }
  &__item {
    border-radius: 3px;
    border: solid 1px #efefef;
    text-align: center;
    padding: 3px;
    img {
      height: 46px;
      object-fit: contain;
      width: 100%;
    }
  }
}
.bankDescription {
  list-style: disc;
  margin-left: 1em;
}
.momoContent {
  border-radius: 3px;
  background-color: #fff5d1;
  padding: 10px;
  &__label {
    font-size: 15px;
    font-weight: bold;
  }
  &__copy {
    height: 40px;
    padding: 8px 10px;
    border-radius: 3px;
    border: solid 1px #00509d;
    background-color: #ffffff;
    position: relative;
    &--code {
      font-size: 18px;
      line-height: 24px;
    }
    &--btn {
      background-color: #00509d;
      color: #fff;
      position: absolute;
      height: 38px;
      padding: 11px 25px;
      border-radius: 0px 3px 3px 0;
      border: solid 1px #00509d;
      right: 0;
      top: 0;
      font-size: 15px;
      line-height: 16px;
      img {
        margin-right: 0.25rem;
        margin-top: -3px;
        height: 16px;
      }
    }
  }
}
.momoNotice {
  padding: 10px 0 20px;
  &__title {
    font-size: 15px;
    font-weight: bold;
  }
}
.redirect-momo {
  height: 50px;
  padding: 15px;
  border-radius: 3px;
  background-color: #ae2070;
  border-color: #ae2070;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  img {
    width: 20px;
    margin-top: -3px;
  }
  &:focus,
  &:hover,
  &:disabled,
  &:active {
    border-color: #ae2070 !important;
    background-color: #ae2070 !important;
    color: #fff !important;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.lh-25 {
  line-height: 25px;
}
.text-super-danger {
  font-weight: bold;
  color: #d31904;
}

.momo-footer {
  text-align: left;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    text-align: center;
    margin-top: 40px;
  }
}
.confirm-momo {
  height: 40px;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: #d31904;
  border-color: #d31904;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: 250px;
  }
  &:focus,
  &:hover,
  &:disabled,
  &:active {
    border-color: #d31904 !important;
    background-color: #d31904 !important;
    color: #fff !important;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.vnpayQr canvas {
  padding: 8px;
  border-radius: 5px;
  border: solid 1px #999999;
}

.vnpayInstructionItem {
  .circle {
    color: #fff;
    background-color: #d68c00;
    width: 28px;
    height: 28px;
    display: inline-table;
    vertical-align: middle;
    border-radius: 50%;
    &__content {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
    margin: 5px;
  }
}

.vnpayMessage {
  span {
    margin: auto;
    border-radius: 3px;
    border: dotted 1px #999999;
    padding: 8px 10px;
    font-size: 18px;
    font-weight: 600;
  }
}

.vnpayNotice {
  border-top: solid 1px #e0e0e0;
  padding: 15px 0;
  &__title {
    color: #666666;
    font-weight: 700;
  }
  &__list {
    list-style: disc;
    padding: 5px 20px;
    li {
      font-style: italic;
      font-size: 15px;
      color: #666;
      &::marker {
        font-size: 18px;
        color: #666;
      }
    }
  }
}

.vnpayInfo {
  &__header {
    img {
      width: 100%;
      border-radius: 3px;
    }
  }
  &__title {
    font-size: 14px;
    color: #333;
    font-weight: 700;
    // margin-left: 10px;
  }
  &__info {
    padding: 0 10px;
  }
  &__item {
    padding: 10px 0;
    &:not(:first-child) {
      border-top: solid 1px #ccc;
    }
  }
  &__detail {
    flex: 1;
    margin-left: 15px;
    &__title {
      color: #ccc;
      font-size: 12px;
      font-weight: 600;
    }
    &__value {
      font-weight: 600;
      color: #fe9523;
    }
  }
}

.vnpayWarning {
  padding: 10px 15px;
  p {
    border: dotted 1px #ff0000;
    background-color: #fff2f2;
    color: #ef0000;
    padding: 10px;
  }
}

.vnpayBtn {
  padding: 10px 15px;
  &__open {
    width: 100%;
    border-radius: 3px;
    background-color: #00509d;
    padding: 15px;
    color: #fff !important;
    &:active {
      background-color: #003e7a;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
  .vnpayNotice {
    border-top: none;
    padding-top: 0;
  }
}

.btnMomo {
  width: 100%;
  border-radius: 3px;
  background-color: #f7ede2;
  font-size: 18px;
  font-weight: bold;
  color: #d68c00;
  position: relative;
  &:hover {
    color: #d68c00;
  }
  .bank-icon {
    position: absolute;
    right: 15px;
    top: 4px;
  }
}
.modalMomoBoxTitle {
  border-radius: 3px;
  border: dashed 2px #ff0000;
  background-image: linear-gradient(to bottom, #fad345, #fcd731);
  text-align: center;
  padding: 12px;
  color: #ef0000;
  height: 50px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
}

.titleColorMomo {
  color: #ef0000;
}

#voucher {
  height: 50px;
  border-radius: 3px;
  border: solid 1px #efefef;
  background-color: #f7f7f7;
  text-align: center;
  &:focus {
    box-shadow: none;
  }
}
.btn-payment {
  // background-image: linear-gradient(180deg, #e8a500, #d68c00);
  background-color: $primaryColor;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
  &:hover {
    color: #fff;
  }
}
.bank-info {
  border-bottom: 1px dotted #707070;
  font-size: 15px;
  color: #333;
  line-height: 1.47;
  flex-direction: column;
  padding-bottom: 20px;
  .bank-content {
    color: #d68c00;
    border: 1px dotted #d68c00;
    margin: auto;
    margin-top: 15px;
    padding: 6px 14px;
    line-height: 27px;
    font-weight: 600;
    .bank-icon img {
      position: relative;
      top: -1px;
      left: 5px;
      cursor: pointer;
    }
  }
  .bank-notice {
    margin-top: 10px;
    .notice-title {
      font-weight: 600;
    }
    .notice-list {
      list-style-type: none;
      .notice-content {
        font-style: italic;
        &:before {
          content: "-";
          text-indent: 1px;
        }
      }
    }
  }
}
.bank-list {
  padding-top: 20px;
  font-size: 15px;
  color: #333;
  line-height: 1.47;
  .bank-list-title {
    font-weight: 600;
  }
  .bank-list-item {
    padding: 12px 20px;
    border-radius: 3px;
    background-color: #fafafa;
    margin-top: 10px;
    .bank-img {
      width: 120px;
      img {
        width: 100%;
      }
    }
    .bank-detail {
      margin-left: 20px;
      font-size: 15px;
      color: #333;
      .bank-detail-bold {
        font-weight: 600;
      }
      .bank-icon img {
        position: relative;
        top: -1px;
        left: 5px;
        cursor: pointer;
      }
      .bank-copied {
        font-size: 13px;
        font-weight: 600;
        color: #2a9d8f;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
.customProvision {
  color: #0d234a !important;
  text-decoration: underline #0d234a;
}
.payment-value {
  margin-bottom: 10px !important;
}
.payment-box {
  margin-bottom: 12px;
  .box-title {
    color: #666;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 8px;
  }
  .payment-child-list {
    flex-wrap: wrap;
    justify-content: space-between;
    .payment-child {
      width: calc(50% - 4px);
      height: 50px;
      background-color: #fafafa;
      border-radius: 3px;
      margin-bottom: 8px;
      padding-top: 3px;
      padding-bottom: 3px;
      cursor: pointer;
      border: 2px outset transparent;
      &:hover {
        background-color: #e2e6ea;
      }
      &.selected {
        border: solid 2px #00509d;
      }
      img {
        object-fit: contain;
        // width: 95px;
        // height: 40px;
      }
    }
  }
  .mDropdown .btnChooseMoney {
    background-color: #fafafa;
    padding: 10px 11px;
    &:after {
      right: 11px !important;
    }
  }
}
.momoCtn {
  .payment-box {
    margin-bottom: 20px;
  }
  .content-payment {
    margin-top: 0 !important;
    .btn-payment {
      margin-top: 0 !important;
    }
  }

  .mDropdown .btnChooseMoney {
    background-color: #fafafa;
    min-height: 50px;
    &:after {
      top: 20px !important;
    }
  }
}
@media (max-width: 420px) {
  .bank-list-item {
    flex-direction: column;
    .bank-detail {
      margin-left: 0 !important;
    }
  }
}
@media (max-width: map-get($grid-breakpoints, "sm")) {
  .modalResp {
    .modal-dialog {
      margin: 0;
      max-width: map-get($grid-breakpoints, "sm");
    }
    .modal-content {
      // min-height: 100vh;
      max-height: calc(100vh - 0rem);
      border: none;
      border-radius: unset;
    }
  }
  .btn-payment {
    min-height: 50px;
  }

  .momoCtn {
    .mDropdown .btnChooseMoney {
      &:after {
      }
    }
  }
}

.primaryColor {
  color: #d68c00;
}
.momoColor {
  color: #ae2070;
}
.modalMomo {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  li {
    color: #666666;
    font-style: italic;
  }
  h5 {
    color: #666666;
    font-size: 0.9375rem;
    font-weight: bold;
  }
}
.customNumberDeposit {
  background-color: #d68c00 !important;
  min-width: 28px !important;
  height: 28px !important;
  color: #fff !important;
  margin-right: 10px;
  width: auto !important;
}

.group {
  display: flex;
}

.customModalClass {
  .modal-body {
    & > div:first-child {
      height: 100%;
    }
  }
}

.groupQr {
  position: relative;
  .logoMini {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .placeholders-qr {
    max-width: 80%;
    height: calc(244px * 0.8);
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

#deposit_amount > input {
  font-size: 18px;
  font-weight: 600;
}

.placeholders-momo {
  height: 190px;
  opacity: 0.8;
}

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
  .customModalClass {
    .customDialog {
      min-width: 734px;
    }

    .modal-body {
      padding: 0 20px 15px 20px;
    }
  }
}
</style>
