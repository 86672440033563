<template>
  <CommonModal
    title="Ngân hàng hỗ trợ QRPay"
    :isOpen="isOpenModalQRPay"
    @updateOpen="$emit('handleOpenModalQRPay')"
    :fullHeight="true"
    :isHiddenFooter="true"
    :modalClass="'customModalClass'"
    @handleHide="$emit('handleHideModalQRPay')"
    :isHideBackdrop="true"
    ><div class="bank">
      <div class="bank__title">Danh sách ngân hàng và ứng dụng hỗ trợ QRPay</div>
      <div class="bank__list">
        <div class="bank__item" v-for="item in qrPayBankList" :key="item">
          <img :src="`https://d1aleksa3dshq6.cloudfront.net/banks/${item}_logo.png`" :alt="item" />
        </div>
      </div></div
  ></CommonModal>
</template>

<script>
import CommonModal from '@/components/CommonModal';
export default {
  components: {
    CommonModal
  },
  props: {
    isOpenModalQRPay: {
      type: Boolean
    }
  },
  data() {
    return {
      qrPayBankList: [
        'abbank',
        'acb',
        'agribank',
        'bacabank',
        'bidv',
        'dongabank',
        'eximbank',
        'hdbank',
        'ivb',
        'mbbank',
        'msbank',
        'namabank',
        'ncb',
        'ocb',
        'ojb',
        'PVComBank',
        'sacombank',
        'saigonbank',
        'scb',
        'shb',
        'techcombank',
        'tpbank',
        'vpbank',
        'seabank',
        'vib',
        'vietabank',
        'vietbank',
        'vccb',
        'vietcombank',
        'vietinbank',
        'bidc',
        'laovietbank',
        'woori',
        'amex',
        'visa',
        'mastercard',
        'jcb',
        'upi',
        'vnmart',
        'vnpayqr',
        '1pay',
        'vimass',
        'vinid',
        'viviet',
        'vnptpay',
        'yolo'
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.bank {
  width: 100%;
  &__title {
    font-size: 15px;
    text-align: left;
    color: #666666;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    padding: 20px 0;
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__item {
    border-radius: 3px;
    border: solid 1px #efefef;
    text-align: center;
    padding: 3px;
    img {
      height: 46px;
      object-fit: contain;
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .customModalClass {
    .customDialog {
      min-width: 734px;
    }

    .modal-body {
      padding: 0 20px 15px 20px;
    }
  }
}
</style>
