<template>
  <b-modal
    headerClass="border-bottom-0"
    footerClass="border-top-0 pt-0"
    modal-class="modalTerm modalCustomMobileSecond"
    v-model="isShow"
    @hide="onCloseModal"
    no-close-on-backdrop
    centered
  >
    <template v-slot:modal-title>
      <p class="d-block pointer font-20 modalDepositHeader">Thanh toán</p>
    </template>
    <div class="d-flex flex-column justify-content-between w-100 modalDepositContent mb-lg-1 h-100 px-0">
      <div class="mt-auto mb-auto" v-if="!directURL">
        <Spinner></Spinner>
      </div>
      <div class="w-100 h-100 web-wrapper" v-else>
        <iframe class="w-100 border-0" :src="directURL" @load="handleLoad"></iframe>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div />
    </template>
  </b-modal>
</template>

<script>
// const Swal = require('sweetalert2');
import Spinner from '@/components/Spinner.vue';
import { DOMAIN_NAME } from '../../common/global';

export default {
  components: {
    Spinner
  },
  props: {
    directURL: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    isShow: {
      get() {
        return this.$store.getters.getterModalDeposit;
      },
      set(newVal) {
        this.$store.commit('setModalDeposit', newVal);
      }
    }
  },
  methods: {
    hideVirtualKeyboard() {
      if (document.activeElement && document.activeElement.blur && typeof document.activeElement.blur === 'function') {
        document.activeElement.blur();
      }
    },
    onCloseModal() {
      this.hideVirtualKeyboard();
      this.isShow = false;
    },
    handleLoad(e) {
      if (e && e.path && e.path[0] && e.path[0].contentWindow && e.path[0].contentWindow.location) {
        const path = e.path[0].contentWindow.location.pathname;
        const host = e.path[0].contentWindow.location.host;
        const search = e.path[0].contentWindow.location.search;
        if (host.includes(DOMAIN_NAME)) {
          if (path !== '/' && search) {
            document.location.replace(document.location.origin + path + search);
          }
          this.onCloseModal();
        }
      }
    }
  },
  watch: {
    isShow: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>

<style lang="scss">
.web-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
}
.modalDepositFooterTxt > a {
  color: #0d234a;
}
.modalDepositHeader {
  color: #0d234a;
}
// .modal-body {
//   height: 100%;
// }
.modalDepositContent {
  min-height: 80vh;
  > div {
    display: flex;
    flex: 1;
  }
  iframe {
    display: flex;
    flex: 1;
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .modalDepositLabel {
    .modalDepositLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalDepositLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
.modalDepositFooter {
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
}
</style>
