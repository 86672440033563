<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <b-form-input
      class="dropdown-input"
      :name="name"
      @focus="onFocus()"
      @blur="exit()"
      @keypress="keyMonitor"
      v-model="searchFilter"
      :placeholder="placeholder"
      autocomplete="false"
      :state="state"
      :formatter="formatter"
      @click="click"
    />

    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div class="dropdown-item" @mousedown="selectOption(option)" v-for="(option, index) in options" :key="index">
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoCompleteInput',
  props: {
    specialAmounts: {
      type: Array,
      required: false,
      default: () => []
    },
    minValue: {
      type: Number,
      required: false
    },
    state: {
      type: Boolean,
      required: false
    },
    formatter: {
      type: Function,
      required: false,
      default: () => {}
    },
    click: {
      type: Function,
      required: false,
      default: () => {}
    },
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: 'Options of dropdown. An array of options with id and name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ''
    };
  },
  created() {
    this.$emit('selected', this.selected);
  },
  methods: {
    updateTextValue(value) {
      this.disabled = true;
      this.searchFilter = this.formatter(value);
    },
    checkAmountValid() {
      if (!this.searchFilter) {
        return;
      }
      const amount = parseInt(this.searchFilter.replace(/\./g, ''));
      if (this.specialAmounts.includes(amount)) {
        return;
      } else if (this.isUpdateToMinValue()) {
        this.$emit('error', `Số tiền phải từ ${this.formatPrice(this.minValue, '')}`);
      } else if (amount % 10000) {
        this.$emit('error', `Số tiền phải chia hết cho 10.000`);
      }
    },
    isUpdateToMinValue() {
      return this.searchFilter && this.minValue && parseInt(this.searchFilter.replace(/\./g, '')) < this.minValue;
    },
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected);
    },
    onFocus() {
      this.disabled = false;
      this.showOptions();
    },
    showOptions() {
      if (!this.disabled) {
        this.optionsShown = true;
      }
    },
    exit() {
      this.checkAmountValid();
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function(event) {
      if (event.key === 'Enter' && this.options[0]) {
        this.selectOption(this.options[0]);
      }
    }
  },
  watch: {
    searchFilter() {
      if (this.options.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.options[0];
      }
      this.showOptions();
      this.$emit('filter', this.searchFilter);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  margin: auto;
  .dropdown-input {
    background: #fff;
    height: 100%;
    &:hover {
      background: #f8f8fa;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;
    .dropdown-item {
      color: $colorBlack;
      font-size: 20px;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      text-align: left;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: #e7ecf5;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
